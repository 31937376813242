$(document).ready(function () {

  $(".mask").mask("+7 (999) 999-99-99");

  let owl = $(".production__slider, .form-popup__slider").owlCarousel({
    loop: true,
    margin: 0,
    nav: true,
    dots: false,
    items: 1,
    navText: ['', ''],
    autoHeight: true,
    autoplay: true,
    autoplayHoverPause: true
  });

  owl.owlCarousel();

  let carousel = $(".works__carousel").owlCarousel({
    loop: true,
    margin: 30,
    nav: true,
    dots: false,
    items: 5,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      480: {
        items: 2
      },
      680: {
        items: 3
      },
      980: {
        items: 4
      },
      1100: {
        items: 5
      }
    }
  });

  carousel.owlCarousel();

  //Smooth Scroll
  $('.fixed-menu__link, .footer__menu-link, .header__calc').on('click', function (e) {
    e.preventDefault();
    let target = $(this).attr('href');
    $('html, body').stop().animate({scrollTop: $(target).offset().top}, 1000);
    return false;
  });

  $('.production__btn').on('click', function (e) {
    e.preventDefault();
    let target = '#' + $(this).attr('data-href');
    $(target).show();
    $('html, body').stop().animate({scrollTop: $(target).offset().top}, 300);
    return false;
  });

  //Mobile menu
  $('.container').on("click", '.hamburger', function (e) {
    e.preventDefault();

    if ($(".fixed-menu__list").hasClass('fixed-menu__list--active')) {
      $(".fixed-menu__list").slideUp(500);
      $(".fixed-menu__list").removeClass('fixed-menu__list--active');
    } else {
      $(".fixed-menu__list").slideDown(500);
      $(".fixed-menu__list").addClass('fixed-menu__list--active');
    }
  });

  //Modal-form
  $('.container').on("click", '.letters__button, .info__button button, .contacts__btn, .footer__call, .works__btn', function (e) {
    e.preventDefault();

    let type = $(this).attr('data-type');

    if ($(".form-modal").hasClass('form-modal--active')) {
      $(".form-modal").removeClass('form-modal--active');
      $(".form-modal .form-modal__type").val('');
    } else {
      $(".form-modal").addClass('form-modal--active');
      $(".form-modal .form-modal__type").val(type);
    }
  });

  //Modal-form
  $('.container').on("click", '.calculator__button', function (e) {
    e.preventDefault();

    if ($(".form-modal--calc").hasClass('form-modal--active')) {
      $(".form-modal--calc").removeClass('form-modal--active');
    } else {
      $(".form-modal--calc").addClass('form-modal--active');
    }
  });

  //Modal-popup-close, Modal-form open
  $('.container').on("click", '.form-popup__btn', function (e) {
    e.preventDefault();
    let type = $(this).attr('data-type');
    $(".form-popup").removeClass('form-popup--active');
    $(".form-modal").addClass('form-modal--active');
    $(".form-modal .form-modal__type").val(type);
  });

  //Modal-popup open
  $('.container').on("click", '.product__link--popup', function (e) {
    e.preventDefault();
    let target = $(this).attr('href');
    $(target).addClass('form-popup--active');
  });

  //Modal-popup, modal-form close
  $('.container').on("click", '.form-modal__close, .form-popup__close', function (e) {
    e.preventDefault();
    $(".form-modal, .form-modal--calc").removeClass('form-modal--active');
    $(".form-popup").removeClass('form-popup--active');
    $(".form-modal .form-modal__type").val('');
  });

  //Modal-popup, modal-form close
  $('.container').on("click", '.form-modal__center', function (e) {
    if ($(e.target).hasClass('form-modal__center')) {
      $(".form-modal, .form-modal--calc").removeClass('form-modal--active');
      $(".form-modal .form-modal__type").val('');
    }
  });

  //Modal-popup, modal-form close
  $('.container').on("click", '.form-popup__center', function (e) {
    if ($(e.target).hasClass('form-popup__center')) {
      $(".form-popup").removeClass('form-popup--active');
    }
  });

  //edit gallery
  $('.container').on("click", '.works__item a', function (e) {
    e.preventDefault();

    let options = {
      text: $(this).attr('data-text'),
      src: $(this).attr('href'),
      type: $(this).attr('data-type'),
    };

    $('.works__big > img').attr('src', options.src);
    $('.works__text').html(options.text);
    $('.works__btn').attr('data-type', options.type);

  });

  $(window).resize(function () {

    if (window.innerWidth >= 1024) {

      $('.fixed-menu__list').removeAttr('style');
      $('.fixed-menu__list').removeClass('fixed-menu__list--active');

    }

  });

});