$(document).ready(function () {
  let pricing = {
    kompozit: 20000,
    kompozitPlus: 35000,
    alum: 21500,
    plastic: 14000,
  };

  let priceMontaj = {
    one: 5000,
    two: 6000,
    three: 8000
  };

  let priceDelivery = {
    one: 3000,
    two: 4000,
    three: 6000
  };

  let magicNumbers = {
    one: 1.5,
    two: 2
  };

  let optionLabels = {
    kompozit: 'Композитный короб',
    kompozitPlus: 'Композитный короб с инкрустацией',
    alum: 'Алюминиевый профиль',
    plastic: 'Пластиковый короб'
  };

  $('.calculator__type-letters .calculator__radio input').on('click', changeInput);
  $('.calculator__letter .calculator__radio input').on('click', changeInput);
  $('.calculator__switch input').on('click', changeInput);
  $('.calculator__letters input').on('input', changeInput);

  function disabledField(field, flag) {
    $(field).prop('disabled', flag);
  }

  function changeInput() {
    if ($('.calculator__letters input')[0].value === '0' || $('.calculator__letters input')[0].value === '' || $('.calculator__letters input')[0].value < 0) {
      $('.calculator__letters input')[0].classList.add('error');
    } else if ($('.calculator__letters input')[1].value === '0' || $('.calculator__letters input')[1].value === '' || $('.calculator__letters input')[1].value < 0) {
      $('.calculator__letters input')[0].classList.remove('error');
      $('.calculator__letters input')[1].classList.add('error');
    } else {
      $('.calculator__letters input')[0].classList.remove('error');
      $('.calculator__letters input')[1].classList.remove('error');
      $('.calculator__button').prop('disabled', false);
      getData($('.calculate'));
    }
  }

  function getData(form) {
    let formData = form.serializeArray();
    let resultData = {};

    $.each(formData, function () {
      resultData[this.name] = this.value;
    });

    editInputs(resultData);
    calculate(resultData);

  }

  function editInputs(data) {
    $('.form-modal__type-letters').val(optionLabels[data.korob]);
    $('.form-modal__height').val(data.height + 'см');
    $('.form-modal__width').val(data.width + 'см');
    $('.form-modal__montaj').val(data.montaj === 'on' ? 'Да' : 'Нет');
    $('.form-modal__delivery').val(data.delivery === 'on' ? 'Да' : 'Нет');
  }

  function calculate(data) {
    console.log(data);
    //Тип светового короба (стоимостные значения указаны выше) х на площадь светового короба, рассчитанная как ширина Х высоту.
    let priceLetter = pricing[data.korob];
    let square = (data.width / 100) * (data.height / 100);
    let resultPriceLetters = parseInt(priceLetter * square);
    let resultPriceMontaj = 0;
    let resultPriceDelvery = 0;

    if (data.montaj === 'on') {
      resultPriceMontaj = calcMontajAndDelivery(data, priceMontaj);
    }

    if (data.delivery === 'on') {
      resultPriceDelvery = calcMontajAndDelivery(data, priceDelivery);
    }

    renderPrice(resultPriceLetters, resultPriceMontaj, resultPriceDelvery);
  }

  function renderPrice(summa, summaMontaj, summaDelivery) {
    let fullSumma = summa + summaMontaj + summaDelivery;

    $('.form-modal__izgotovlenie').val(summa);
    $('.form-modal__montaj-price').val(summaMontaj);
    $('.form-modal__delivery-price').val(summaDelivery);
    $('.form-modal__full-price').val(fullSumma);
    $('.calculator__izgotov .calculator__summa').html(summa);
    $('.calculator__montaj .calculator__summa').html(summaMontaj);
    $('.calculator__delivery .calculator__summa').html(summaDelivery);
    $('.calculator__itogo .calculator__summa').html(fullSumma);
  }

  function calcMontajAndDelivery(data, type) {
    let square = (data.width / 100) * (data.height / 100);
    console.log(square);
    return (square < magicNumbers.one) ? type.one :
        (square >= magicNumbers.one && square < magicNumbers.two) ? type.two : type.three;
  }

});