//обработчик формы Быстрый Звонок
$(document).ready(function () {

  $('.send', this).submit(function () {

    if ($(this).data('formstatus') !== 'submitting') {

      var form = $(this),
          formData = form.serialize(),
          formUrl = form.attr('action'),
          formMethod = form.attr('method'),
          responseMsg = form.find('.response-site');

      form.data('formstatus', 'submitting');

      $.ajax({
        url: formUrl,
        type: formMethod,
        data: formData,
        success: function (data) {

          var responseData = jQuery.parseJSON(data),
              klass = '';

          switch (responseData.status) {
            case 'error':
              klass = 'response-error-site';
              break;
            case 'success':
              klass = 'response-success-site';
              break;
          }

          responseMsg.fadeOut(200, function () {

            if (klass == 'response-success-site') {
              //form.find('.reservation__input input[name="name"]').val('');
              //form.find('.reservation__input input[name="phone"]').val('');
              form.find('input[name="name"]').removeClass('input-err');
              form.find('input[name="tel"]').removeClass('input-err');

              $(this).removeClass('response-waiting-site')
                  .addClass(klass)
                  .html(responseData.message)
                  .slideDown(300, function () {
                    setTimeout(function () {
                      $(responseMsg).hide();
                    }, 7000);
                  });


              setTimeout(function () {
                $(this).removeClass(klass);
                form.data('formstatus', 'idle');
              }, 2000);

              document.location.href = './sanks.html';
              document.forms['modal-form'].reset();
              document.forms['modal-form--calc'].reset();
            } else {
              $(this).removeClass('response-waiting-site');

              if (responseData.type == 'name') {
                form.find('input[name="name"]').addClass('input-err');
              } else if (responseData.type == 'phone') {
                form.find('input[name="tel"]').addClass('input-err');
              }

              if (form.find('input[name="name"]').val() != '') {
                form.find('input[name="name"]').removeClass('input-err');
              }
              if (form.find('input[name="tel"]').val() != '') {
                form.find('input[name="tel"]').removeClass('input-err');
              }

              setTimeout(function () {
                form.data('formstatus', 'idle');
              }, 2000);
            }

          });
        }
      });
    }

    return false;
  });

});
